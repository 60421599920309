// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import humps from 'humps';

const convertCallback: humps.HumpsProcessor = (key, convert) => {
  const lastSymbol = key.slice(-1);
  const newKey = convert(key);
  return lastSymbol === '-' ? `${newKey}-` : newKey;
};

export function camelize<T>(obj: T) {
  // ignore camelize/decamelize in the type system
  return humps.camelizeKeys(obj, convertCallback) as unknown as T;
}

export function decamelize<T>(object: T): T {
  // ignore camelize/decamelize in the type system
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map(item => decamelize(item)) as unknown as T;
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce((acc, next) => {
        acc[humps.decamelize(next)] = decamelize(object[next]);
        return acc;
      }, {} as T);
    }
  }

  return object;
}
