// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import logo from 'assets/logo.svg';
import loginLogo from 'assets/logo-bg-white.svg';

export default {
  name: 'LearnCommunity',
  logoUrl: logo,
  loginLogoUrl: loginLogo,
  headerBackgroundColor: '#383c45',
  headerTextColor: '#ffffff',
  organizationUrl: gon.appHost,
};
