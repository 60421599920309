// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import {
  adjust,
  append as Rappend,
  assoc,
  curry,
  dissoc,
  either,
  filter,
  find,
  flatten,
  fromPairs,
  is,
  isEmpty,
  isNil,
  lensPath,
  map,
  omit,
  over,
  path as Rpath,
  pipe,
  pluck,
  prepend as Rprepend,
  prop,
  propEq,
  reject,
  set as Rset,
  sort,
  sortBy,
  tail,
  toPairs,
  uniq,
  when,
} from 'ramda';

import { uuid } from 'uuidv4';

export const possessive = name => (name?.slice(-1) === 's' ? `${name}'` : `${name}'s`);

const rejectById = id => reject(propEq('id', id));

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const mergeIfSameId = (mergeId, mergeAttrs, original) =>
  original.map(el => (el.id !== mergeId ? el : { ...el, ...mergeAttrs }));

export const change = (object, path, func) => {
  const lens = lensPath(path);
  return over(lens, func, object);
};

export const rejectByUuid = uuidField => reject(propEq('uuid', uuidField));

export const mergeById = (object, path, id, attrs) => {
  const lens = lensPath(path);
  return over(lens, mergeIfSameId.bind(null, id, attrs), object);
};

export const set = (object, path, value) => {
  const lens = lensPath(path);
  return Rset(lens, value, object);
};

export const merge = (object, path, attrs) => {
  const lens = lensPath(path);
  return over(lens, value => ({ ...value, ...attrs }), object);
};

export const removeById = (object, path, id) => {
  const lens = lensPath(path);
  return over(lens, rejectById(id), object);
};

export const removeByUuid = (object, path, uuidField) => {
  const lens = lensPath(path);
  return over(lens, rejectByUuid(uuidField), object);
};

export const append = (object, path, element) => {
  const lens = lensPath(path);
  return over(lens, Rappend(element), object);
};

export const prepend = (object, path, element) => {
  const lens = lensPath(path);
  return over(lens, Rprepend(element), object);
};

const makeTree = (arr, levels, parentId = null) => {
  const restLevels = tail(levels);
  const firstLevelCollection = filter(item => item.parentId === parentId, arr);
  const restLevelsCollection = filter(item => item.parentId !== parentId, arr);

  return map(
    item => ({
      ...item,
      children: makeTree(restLevelsCollection, restLevels, item.id),
    }),
    firstLevelCollection,
  );
};

const sortByField = field => sortBy(prop(field));

export const sortTreeByField = (arr, field) => {
  const sortedLevel = sortByField(field)(arr);
  return sortedLevel.map(element =>
    element.children && element.children.length > 0
      ? { ...element, children: sortTreeByField(element.children, field) }
      : element,
  );
};

export const makeTreeFromFlattenArray = array => {
  const levels = pipe(
    pluck('parentId'),
    uniq,
    sort((a, b) => a - b),
  )(array);
  return makeTree(array, levels);
};

export const flattenTree = (arr = []) =>
  arr.reduce((acc, value) => {
    if (isEmpty(value.children)) {
      acc.push(omit('children', value));
      return acc;
    }
    return flatten([...acc, value, flattenTree(value.children)]);
  }, []);

export const renameKeysBy = curry((fn, obj) => pipe(toPairs, map(adjust(0, fn)), fromPairs)(obj));

const isArrayOrObject = either(is(Array), is(Object));
export const isBlank = either(isEmpty, isNil);

export const compactParams = when(
  isArrayOrObject,
  pipe(
    map(a => compactParams(a)),
    reject(isBlank),
  ),
);

export const rejectNilParams = when(
  isArrayOrObject,
  pipe(
    map(a => rejectNilParams(a)),
    reject(isNil),
  ),
);

export { yupToFormErrors } from './yupToFormErrors';

export const findById = id => find(propEq('id', id));

export const appendOrMergeById = (object, path, id, attrs) => {
  const source = Rpath(path, object);
  const isExist = findById(id)(source);
  return isNil(isExist) ? append(object, path, { id, ...attrs }) : mergeById(object, path, id, { ...attrs });
};

export const addUuids = value => {
  if (is(Array, value)) return map(addUuids, value);
  if (is(Object, value)) return { ...map(addUuids, value), uuid: uuid() };
  return value;
};

export const removeUuids = value => {
  if (is(File, value)) return value;
  if (is(Array, value)) return map(removeUuids, value);
  if (is(Object, value)) return dissoc('uuid', { ...map(removeUuids, value) });
  return value;
};

export const markForDestroy = curry((field, fieldValue, items) =>
  map(when(propEq(field, fieldValue), assoc('_destroy', true)), items),
);

export const markObjectWithFieldValueToDestroy = (object, path, field, fieldValue) => {
  const lens = lensPath(path);
  return over(lens, markForDestroy(field, fieldValue), object);
};

export const makeTreeFromFlatList = arr => {
  const tree = {
    children: [],
  };
  const ptrs = [[1, tree]]; // stack
  arr.forEach(item => {
    const indent = item.indentation;
    while (ptrs.length && ptrs[ptrs.length - 1][0] >= indent) {
      ptrs.pop();
    }
    const parent = ptrs.length ? ptrs[ptrs.length - 1][1] : tree;
    const obj = { ...item, children: [] };
    parent.children.push(obj);
    ptrs.push([indent, obj]);
  });
  return tree.children;
};

export const removeSpecialCharacter = value => {
  return value.replace(/[^A-Z0-9]+/gi, ' ').trim();
};

export const removeSpecialCharacterFromSearchTerm = value => {
  return value
    .replace(/[^A-Z0-9]+/gi, ' ')
    .trim()
    .split(' ');
};

export const ensureHttps = url => {
  if (isEmpty(url) || url.startsWith('https')) {
    return url;
  }
  return url.startsWith('http') ? url.replace('http', 'https') : `https://${url}`;
};

// Regular expression to match both URLs and domains
// examples: https://regex101.com/r/r4y6NE/1
export const urlOrDomainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}(\/.*)?$/;
