export default {
  settingsPath: () => '/settings',

  settingsWhiteLabelingPath: () => '/settings/display_appearance',
  oldSettingsWhiteLabelingPath: () => '/settings/white_labeling',
  settingsAccessControlsPath: () => '/settings/access_controls',
  settingsSingleSignOnPath: () => '/settings/single_sign_on',
  settingsAdministratorsPath: () => '/settings/administrators',
  settingsMembershipsPath: () => '/settings/memberships',
  settingsStatusesPath: () => '/settings/statuses',
  settingsStatusesPrivacyPath: () => '/settings/statuses/privacy',
  settingsProfilePath: () => '/settings/profile',
  settingsFormTemplatesPath: () => '/settings/form_templates',
  settingsNewFormTemplatePath: () => '/settings/form_templates/new',
  settingsEditFormTemplatePath: id => `/settings/form_templates/${id}`,
  settingsWorkflowsPath: () => '/settings/workflows',
  settingsNewWorkflowPath: () => '/settings/workflows/new',
  settingsEditWorkflowPath: id => `/settings/workflows/${id}`,
  dataSourcesPath: () => '/settings/data_sources',
  settingsPublicProfilePath: () => '/settings/public_profile',
  settingsCanvasAppsPath: () => '/settings/canvas_apps',
  settingsTagsPath: () => '/settings/tags',
  settingsTagGroupsPath: () => '/settings/tags/groups',
  settingsTagGroupsNewPath: () => '/settings/tags/groups/new',
  settingsTagGroupsEditPath: id => `/settings/tags/groups/${id}`,
  settingsFiltersPath: () => '/settings/tags/filters',
  settingsFiltersNewPath: () => '/settings/tags/filters/new',
  settingsFiltersEditPath: id => `/settings/tags/filters/${id}`,
  settingsFilterPath: () => '/settings/tags/filters',
  settingsEssaFilterEditPath: () => `/settings/tags/essa_filters`,
  settingsTagListsPath: () => '/settings/tags/lists',
  settingsTagListsNewPath: () => '/settings/tags/lists/new',
  settingsTagListsEditPath: id => `/settings/tags/lists/${id}`,
  settingsColumnsPath: () => '/settings/columns',
  settingsSmartLaunchPath: () => '/settings/smart_launch',
};
