// This component has been migrated to V3
// If you make changes to this component, please drop a note in #lp-instui-react-upgrade

import queryString from 'query-string';

export const openNewTab = url => {
  window.open(url, '_blank');
};

export const reloadPage = () => {
  window.location.reload();
};

export const redirectWithReloadPage = url => {
  window.location.replace(url);
};

export const stringifyParams = params => queryString.stringify(params, { arrayFormat: 'bracket' });

export const setLocationHash = hash => {
  window.location.hash = hash;
};
